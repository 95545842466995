<template>
    <div v-if="canAccess('liquidaciones_read')">
        <BlockUI :blocked="loading" :fullScreen="true" style="position:absolute;top:50%;left:50%;">
            <i v-if="loading" class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
        </BlockUI>
        <div class="p-grid">
            <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items" class="p-col-12 p-breadcrumb p-component"/>
          <div class="col-md-12" style="width: 100%" v-if="observationRevert!==null">
           <div class="row">
             <br>
             <div class="notification-revert">
               <b>Observación de reverso de liquidación:</b>&nbsp;{{observationRevert}}
             </div>
             <hr/>
           </div>
          </div>
            <Fieldset legend="Detalle" :toggleable="false" class="p-col-12">
                <div class="p-grid">
                    <div class="p-col-12">
                        <div class="hide">
                            {{fecha = liquidationData.head?.generate_at}}
                            {{id = liquidationData.head?.id}}
                            {{mes = liquidationData.head?.liquidation_month?.month_process}}
                            {{semana = liquidationData.head?.liquidation_week?.week_process}}
                            {{nombre = liquidationData.head?.employee?.name}}
                            {{orden = liquidationData.head?.payment_order}}
                            {{fechaliq = liquidationData.head?.generate_at}}
                            {{cargo = liquidationData.head?.employee?.position?.description}}
                            {{filial = liquidationData.head?.employee?.subsidiary?.name}}
                            {{cc = liquidationData.head?.employee?.dni}}
                            {{pais = liquidationData.head?.country?.name}}
                        </div>
                        <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="fecha" class="p-inputtext-sm p-col-12" :disabled="true"/>
                            <label for="">Fecha</label>
                        </span>
                        </div>
                        <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="id" class="p-inputtext-sm p-col-12" :disabled="true"/>
                            <label for="">Cons. No</label>
                        </span>
                        </div>
                        <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="mes" class="p-inputtext-sm p-col-12" :disabled="true"/>
                            <label for="">Mes Liquidación</label>
                        </span>
                        </div>
                        <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="semana" class="p-inputtext-sm p-col-12" :disabled="true"/>
                            <label for="">Semana Liquidación</label>
                        </span>
                        </div>
                        <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="nombre" class="p-inputtext-sm p-col-12" :disabled="true"/>
                            <label for="">Nombre de asesor</label>
                        </span>
                        </div>
                        <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="orden" class="p-inputtext-sm p-col-12" :disabled="true"/>
                            <label for="">Orden de pago</label>
                        </span>
                        </div>
                        <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="fechaliq" class="p-inputtext-sm p-col-12" :disabled="true"/>
                            <label for="">Fecha de liquidación</label>
                        </span>
                        </div>
                        <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="cargo" class="p-inputtext-sm p-col-12" :disabled="true"/>
                            <label for="">Cargo</label>
                        </span>
                        </div>
                        <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="filial" class="p-inputtext-sm p-col-12" :disabled="true"/>
                            <label for="">Filial</label>
                        </span>
                        </div>
                        <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="cc" class="p-inputtext-sm p-col-12" :disabled="true"/>
                            <label for="">C.C</label>
                        </span>
                        </div>
                      <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="pais" class="p-inputtext-sm p-col-12" :disabled="true"/>
                            <label for="">País</label>
                        </span>
                        </div>
                    </div>
                </div>
            </Fieldset>
            <Fieldset legend="Comisiones" :toggleable="false" class="p-unique">
                <ProgressBar mode="indeterminate" v-if="loading"/>
                <DataTable :value="items"  :key="items"
                    dataKey="id" v-model:selection="currentItem" selectionMode="single"
                    @row-select="selectData()">
                    <template #header>
                        <Toolbar class="p-col-12 p-mb-1">
                            <template #left>
                                Contratos (Matriculas)
                            </template>
                            <template #right>
                                Total de Comisiones: {{liquidationData.total_body}}
                            </template>
                        </Toolbar>
                    </template>

                        <!-- <template v-if="fTR !== 1"> -->
                        <Column headerStyle="width: 50px">
                        <template #body="slotProps" >
                                <i class="pi pi-info-circle" style="color: #2196F3"
                                        @click="openContract(slotProps.data.process_enrollment)"/>
                        </template>
                        </Column>
                        <Column headerStyle="width: 50px">
                        <template #body="slotProps" >
                                <i class="pi pi-info-circle" style="color: #689F38"
                                        @click="openCommissions(slotProps.data.process_enrollment)"/>
                        </template>
                        </Column>
                        <Column field="contract" header="MATRICULA" :sortable="true"  ></Column>
                        <Column field="contract" header="MATRICULA" :sortable="true"  ></Column>
                        <Column field="student_last_name" header="APELLIDOS" :sortable="true"  ></Column>
                        <Column field="student_name" header="NOMBRES" :sortable="true"></Column>
                        <Column field="plan" header="P.BASICO" :sortable="true"></Column>
                        <Column field="sum_commission_value" header="VALOR" :sortable="true"></Column>
                        <!-- </template>
                        <template v-else-if="fTR === 2" #body="slotProps">
                            <Column header="MATRICULA" :sortable="true"  >
                                <div class="fTR">
                                    {{slotProps.data.contract}}
                                </div>
                            </Column>
                            <Column header="APELLIDOS" :sortable="true"  >
                                <div class="fTR">
                                    {{slotProps.data.student_last_name}}
                                </div>
                            </Column>
                            <Column header="NOMBRES" :sortable="true"  >
                                <div class="fTR">
                                    {{slotProps.data.student_name}}
                                </div>
                            </Column>
                            <Column header="P.BASICO" :sortable="true"  >
                                <div class="fTR">
                                    {{slotProps.data.plan}}
                                </div>
                            </Column>
                            <Column header="VALOR" :sortable="true"  >
                                <div class="fTR">
                                    {{slotProps.data.sum_commission_value}}
                                </div>
                            </Column>
                        </template> -->
                </DataTable>
                <br/>

                <ProgressBar mode="indeterminate" v-if="loading"/>
                <DataTable class="t-margin" :value="items2"  :key="items2">
                    <template #header>
                        <Toolbar class="p-col-12 p-mb-1">
                            <template #left v-if="currentItem">
                                Comisiones por Matricula Seleccionada: {{currentItem.contract}}
                            </template>
                        </Toolbar>
                    </template>
                    <Column field="student_name" header="ALUMNO" :sortable="true"  ></Column>
                    <Column field="contract" header="MATRICULA" :sortable="true"  ></Column>
                    <Column field="plan" header="P.BASICO" :sortable="true"></Column>
                    <Column field="enrollment_value" header="Vr.MATRICULA" :sortable="true"></Column>
                  <Column field="position" header="CARGO" :sortable="true">
                    <template #body="slotProps">
                      {{slotProps.data.position}}
                    </template>
                  </Column>
                    <Column field="commission_value" header="VALOR" :sortable="true"></Column>
                </DataTable>
            </Fieldset>
            <Fieldset legend="Liquidación" :toggleable="false" class="p-unique">
                <ProgressBar mode="indeterminate" v-if="loading"/>
                <DataTable :value="items3" :key="items3">
                    <template #header>
                        <Toolbar class="p-col-12 p-mb-1">
                            <template #left>
                                Liquidación
                            </template>
                          <template #right>
                            <Button :disabled="disable" label="Regenerar liquidaciones" icon="pi pi-plus" class="p-button-info p-mr-2"
                                    @click="generateDialog = true" />
                          </template>
                        </Toolbar>
                    </template>

                    <Column field="code" header="CODIGO" :sortable="true"  ></Column>
                    <Column field="code_description" header="DESCRIPCIÓN" :sortable="true" >
                        <template #body="slotProps">
                                <Dropdown style="padding: 0.2rem !important;" :disabled="disable" v-model="listaSel"
                                :options="lista"
                                optionLabel="name"
                                :placeholder="slotProps.data.code_description"
                                @change="newCode(slotProps.data.id)" />
                        </template>
                    </Column>
                    <Column header="DEBITOS" :sortable="true">
                        <template #body="slotProps">
                            <div class="center" v-if="slotProps.data.is_debit">
                                <InputText :disabled="disable" v-bind:id="'Input'+slotProps.data.id" :value="slotProps.data.value"
                                           @keydown.enter="updateLiq(slotProps.data.id, 'value')"
                                           @blur="updateLiq(slotProps.data.id, 'value')"/>
                            </div>
                            <div class="center" v-else>0</div>
                        </template>
                    </Column>
                    <Column header="CREDITOS" :sortable="true">
                        <template #body="slotProps">
                            <div class="center" v-if="slotProps.data.is_credit">
                                <InputText :disabled="disable"  v-bind:id="'Input'+slotProps.data.id" :value="slotProps.data.value" @blur="updateLiq(slotProps.data.id, 'value')" @keydown.enter="updateLiq(slotProps.data.id, 'value')"/>
                            </div>
                            <div class="center" v-else>0</div>
                        </template>
                    </Column>
                    <Column field="description" header="OBSERVACIONES" :sortable="true">
                    <template #body="slotProps">
                        <div class="center">
                            <InputText :disabled="disable" v-bind:id="'Description'+slotProps.data.id"  :placeholder="slotProps.data.description" @blur="updateLiq(slotProps.data.id, 'description')" @keydown.enter="updateLiq(slotProps.data.id, 'description')"/>
                        </div>
                    </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                                <Button :disabled="disable" icon="pi pi-trash" class="p-button-rounded p-button-warning"
                                        @click="confirmDelete(slotProps.data)"/>
                        </template>
                    </Column>

                </DataTable>
            </Fieldset>
            <Fieldset legend="Otros valores" :toggleable="false" class="p-unique">
                <ProgressBar mode="indeterminate" v-if="loading"/>
                <DataTable :value="items4"  :key="items4" class="table-fix">
                    <template #header>
                        <Toolbar class="p-col-12 p-mb-1">
                            <template #left>
                                Otros valores
                            </template>
                        </Toolbar>
                    </template>

                    <Column headerStyle="width: 15%" field="code" header="CONCEPTO" :sortable="true" >
                        <template #body="slotProps">
                                <!-- <Dropdown :disabled="disable" v-model="listaSelOtro"
                                :options="listaOtros"
                                optionLabel="name"
                                :placeholder="slotProps.data.code_description"
                                /> -->
                                {{slotProps.data.code_description}}
                        </template>
                    </Column>
                    <Column headerStyle="width: 15%" field="enrollment" header="MATRICULA No" :sortable="true" >
                    <template #body="slotProps">
                        <div class="center">
                            {{slotProps.data.enrollment}}
                            <!-- <InputText :disabled="disable" v-model="inputOthers" :placeholder="slotProps.data.enrollment" @keydown.enter="updateDC(slotProps.data.id)"/> -->
                        </div>
                    </template>
                    </Column>
                    <Column headerStyle="width: 15%" field="debits" header="DEBITOS" :sortable="true">
                    <template #body="slotProps">
                        <div class="center">
                            {{getAbusoluteValue(slotProps.data.debits)}}
                            <!-- <InputText :disabled="disable" v-model="inputOthers" :placeholder="slotProps.data.debits" @keydown.enter="updateDC(slotProps.data.id)"/> -->
                        </div>
                    </template>
                    </Column>
                    <Column headerStyle="width: 15%" field="credits" header="CREDITOS" :sortable="true">
                    <template #body="slotProps">
                        <div class="center">
                            {{slotProps.data.credits}}
                            <!-- <InputText :disabled="disable" v-model="inputOthers" :placeholder="slotProps.data.credits" @keydown.enter="updateDC(slotProps.data.id)"/> -->
                        </div>
                    </template>
                    </Column>
                    <Column headerStyle="width: 15%" field="description" header="OBSERVACIONES" :sortable="true">
                    <template #body="slotProps">
                        <div class="center">
                            {{slotProps.data.description}}
                            <!-- <InputText :disabled="disable" v-model="inputOthers" :placeholder="slotProps.data.description" @keydown.enter="updateDC(slotProps.data.id)"/> -->
                        </div>
                    </template>
                    </Column>
                    <Column headerStyle="width: 15%" header="" field="id">
                      <template #body="slotProps">
                        <div class="center">
                          <Button  icon="pi pi-trash" class="p-button-warning p-button-rounded"
                                  @click="itemDeleteOthersValues=slotProps.data.id;deleteDialogOtherValues=true"        />
                        </div>
                      </template>
                    </Column>
                    <template #footer>
                        <tr class="tFooter">
                            <td><Dropdown class="df" v-model="listaSelOtro"
                            :options="listaOtros"
                            optionLabel="name"
                            placeholder="Concepto"
                            /></td>
                            <td><InputText :disabled="disable" v-model="inputOthers[0]"/></td>
                            <td><InputNumber showButtons :min="0" mode="currency" currency="USD" locale="en-US" currencyDisplay="symbol" :disabled="disable" v-model="inputOthers[1]"/></td>
                            <td><InputNumber showButtons :min="0" mode="currency" currency="USD" locale="en-US" currencyDisplay="symbol" :disabled="disable" v-model="inputOthers[2]"/></td>
                            <td><InputText :disabled="disable" v-model="inputOthers[3]"/></td>
                            <td><Button :disabled="disable" icon="pi pi-save" class="p-button-success p-button-rounded"
                            @click="addOther()"        /></td>
                        </tr>
                    </template>
                </DataTable>
            </Fieldset>
            <Fieldset legend="Prestamos" :toggleable="false" class="p-unique">
                <ProgressBar mode="indeterminate" v-if="loading"/>
                <DataTable :value="items5"  :key="items5" class="table-fix">
                    <template #header>
                        <Toolbar class="p-col-12 p-mb-1">
                            <template #left>
                                Prestamos
                            </template>
                        </Toolbar>
                    </template>
                    <Column headerStyle="width: 15%" field="code" header="CONCEPTO" :sortable="true" >
                        <template #body="slotProps">
                                {{slotProps.data.code_description}}
                        </template>
                    </Column>
                    <Column headerStyle="width: 15%" field="value_loan" header="Valor Prestamo" :sortable="true" ></Column>
                    <Column headerStyle="width: 15%" field="date_at" header="Fecha" :sortable="true"></Column>
                    <Column headerStyle="width: 15%" field="value_amortize" header="Saldo Ant. Vr. Amortizar" :sortable="true"></Column>
                    <Column headerStyle="width: 15%" field="balance" header="Saldo" :sortable="true"></Column>
                      <Column headerStyle="width: 15%" header="" field="id">
                        <template #body="slotProps">
                          <div class="center">
                            <Button  icon="pi pi-trash" class="p-button-warning p-button-rounded"
                                     @click="itemDeleteLoans=slotProps.data.id;deleteDialogLoans=true"        />
                          </div>
                        </template>
                      </Column>
                    <template #footer>
                        <!-- <div class="p-field p-col-12"> -->
                            <tr class="tFooter">
                            <td><Dropdown class="df" :disabled="disable" v-model="listaSelConcepto"
                            :options="listaConceptos"
                            optionLabel="name"
                            placeholder="Concepto"
                            /></td>
                            <td><InputNumber showButtons :min="0" mode="currency" currency="USD" locale="en-US" currencyDisplay="symbol" :disabled="disable" v-model="inputOthers[4]"/></td>
                            <td><Calendar :disabled="disable" id="" :showIcon="true" v-model="dateSel" dateFormat="yy-mm-dd"
                                    :yearNavigator="true" yearRange="2000:2050" /></td>
                            <td><InputNumber showButtons :min="0" mode="currency" currency="USD" locale="en-US" currencyDisplay="symbol" :disabled="disable" v-model="inputOthers[5]"/></td>
                            <td><InputNumber showButtons :min="0" mode="currency" currency="USD" locale="en-US" currencyDisplay="symbol" :disabled="disable" v-model="inputOthers[6]"/></td>
                            <td><Button :disabled="disable" icon="pi pi-save" class="p-button-success p-button-rounded"
                 @click="addLoan()"        /></td>
                            </tr>
                        <!-- </div> -->

                    </template>
                </DataTable>
            </Fieldset>

            <Dialog v-model:visible="commissionsDialog" :style="{width: '950px'}" header="Información de Comisiones" :modal="true">
                <DataTable :value="itemsT" scrollable :key="itemsT" class="table">
                        <Column field="position.description" header="ASESOR" :sortable="true"  ></Column>
                        <Column field="employee_with_ancestors.name" header="NOMBRE" :sortable="true" headerStyle="width: 350px" ></Column>
                        <Column field="cost" header="$ Comisión" :sortable="true"  ></Column>
                        <Column field="approved" header="SE PAGA">
                        <template #body="slotProps">
                            <template v-if="slotProps.data.approved === 'n'">
                                <Checkbox v-model="slotProps.data.approved" :binary="false" disabled="true" />
                                <!-- {{slotProps.data.approved}} -->
                            </template>
                            <!-- <template v-if="slotProps.data.approved === 'n'">
                                {{slotProps.data.approved = true}}
                            </template> -->
                            <template v-else-if="slotProps.data.approved === 'y'">
                                <Checkbox v-model="slotProps.data.approved" :binary="slotProps.data.approved" disabled="true" />
                                <!-- {{slotProps.data.approved}} -->
                            </template>

                        </template>
                        </Column>
                </DataTable>
            </Dialog>
            <Dialog v-model:visible="contractDialog" :style="{width: '950px'}" header="Información de Matricula" :modal="true">
                <div class="p-grid">
                    <div class="p-col-12">
                        <div class="hide">
                            {{id = commissionData.enrollment?.contract?.holder?.id}}
                            {{nombre = commissionData.enrollment?.contract?.holder?.name +" "+ commissionData.enrollment?.contract?.holder?.last_name}}
                            {{matricula = commissionData.enrollment?.sequential}}
                            {{plan = commissionData.enrollment?.plan?.name}}
                            {{asesor = commissionData.enrollment?.employee?.name}}
                            {{estado = commissionData.enrollment?.status}}
                            {{filiar = commissionData.enrollment?.subsidiary?.name}}
                            {{valor = commissionData.enrollment?.plan?.cost}}
                            {{mes = commissionData.enrollment?.month_id}}
                            {{semana = commissionData.enrollment?.week?.week}}
                            {{mesP = commissionData.month_post?.month}}
                            {{semanaP = commissionData.week_post?.week}}
                            {{registro = commissionData.enrollment?.registration}}
                        </div>
                        <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="id" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label >Id Titular</label>
                        </span>
                        </div>
                        <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="nombre" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label >Nombre de Titular</label>
                        </span>
                        </div>
                        <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="matricula" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label >No Matricula</label>
                        </span>
                        </div>
                        <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="plan" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label >Plan de Pago</label>
                        </span>
                        </div>
                        <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="asesor" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label >Nombre de asesor</label>
                        </span>
                        </div>
                        <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="estado" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label >Estado de Matricula</label>
                        </span>
                        </div>
                        <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="commissionData.created_at" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label >Fecha de Ingreso</label>
                        </span>
                        </div>
                        <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="filiar" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label >Filial</label>
                        </span>
                        </div>
                        <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="valor" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label >Valor Plan</label>
                        </span>
                        </div>
                        <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="registro" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label >Fecha de verificación</label>
                        </span>
                        </div>
                        <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="commissionData.status" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label >Estado de Comisiones</label>
                        </span>
                        </div>
                        <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="mes" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label >Mes</label>
                        </span>
                        </div>
                        <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="semana" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label >Semana</label>
                        </span>
                        </div>
                        <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText  type="text" v-model="commissionData.invoice_number" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label >No. Factura</label>
                        </span>
                        </div>
                        <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="commissionData.registry_number" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label >No. Registro</label>
                        </span>
                        </div>
                        <br/>

                        <template v-if="commissionData.month_post">
                            <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="mesP" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label >Mes Postergado</label>
                        </span>
                        </div>
                        </template>
                        <template v-if="commissionData.week_post">
                        <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="semanaP" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label >Semana Postergado</label>
                        </span>
                        </div>
                        </template>
                    </div>
                </div>
            </Dialog>
            <Dialog v-model:visible="deleteDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
                <div class="confirmation-content">
                    <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
                    <span v-if="deleteItem">Seguro de eliminar la liquidación?</span>
                </div>
                <template #footer>
                    <Button label="No" icon="pi pi-times" class="p-button-text"
                            @click="deleteDialog = false"/>
                    <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteRow"/>
                </template>
            </Dialog>
            <Dialog v-model:visible="generateDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
                <div class="confirmation-content">
                    <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
                    <span>Seguro de volvera a generar la liquidación?</span>
                </div>
                <template #footer>
                    <Button label="No" icon="pi pi-times" class="p-button-text"
                            @click="generateDialog = false"/>
                    <Button label="Si" icon="pi pi-check" class="p-button-text" @click="generateLiq()"/>
                </template>
            </Dialog>
            <Dialog v-model:visible="aprDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
                <div class="confirmation-content">
                    <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
                    <span>Esta seguro de aprobar la liquidación?</span>
                </div>
                <template #footer>
                    <Button label="No" icon="pi pi-times" class="p-button-text"
                            @click="aprDialog = false"/>
                    <Button label="Si" icon="pi pi-check" class="p-button-text" @click="changeStatus('Aprobada')"/>
                </template>
            </Dialog>

            <Dialog v-model:visible="pdf" :style="{width: '1050px'}" header="Resumen de Liquidación" :modal="true">
                    <div id="pdfContainer" v-html="info">
                    </div>
                    <template #footer>
                        <Button label="Generar PDF" class="p-button-success" @click="generatePDF"/>
                    </template>
            </Dialog>
          <Dialog v-model:visible="deleteDialogOtherValues" :style="{width: '450px'}" header="Confirmar" :modal="true">
            <div class="confirmation-content">
              <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
              <span v-if="itemDeleteOthersValues">Seguro de eliminar el registro?</span>
            </div>
            <template #footer>
              <Button label="No" icon="pi pi-times" class="p-button-text"
                      @click="deleteDialogOtherValues = false"/>
              <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteRowOtherValues"/>
            </template>
          </Dialog>
          <Dialog v-model:visible="deleteDialogLoans" :style="{width: '450px'}" header="Confirmar" :modal="true">
            <div class="confirmation-content">
              <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
              <span v-if="itemDeleteLoans">Seguro de eliminar el registro?</span>
            </div>
            <template #footer>
              <Button label="No" icon="pi pi-times" class="p-button-text"
                      @click="deleteDialogLoans = false"/>
              <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteRowLoans"/>
            </template>
          </Dialog>


          <div class="valButtons">
                <Button label="Aprobar" icon="pi pi-plus" class="p-button-success p-mr-2"
                @click="aprDialog = true" v-if="liquidationData.head?.status === 'Nuevo'"   />
            <Button label="Aprobar" icon="pi pi-plus" class="p-button-secondary p-mr-2"
                    v-else-if="liquidationData.head?.status === 'Aprobada'" />
                <Button label="Ver PDF" class="p-button-info p-mr-2" @click="getPdf"
                    />
            </div>
            <template v-if="liquidationData" >
            <div class="p-grid p-fieldset full" v-if="liquidationData.accumulate_commissions">
                <div class="p-col">
                    <div class="p-grid">
                        <div class="p-col  inside">
                        <div class="tTitle">Acumulado Anterior:</div>
                        <div class="tTitle">Presente Semana:</div>
                          <div class="tTitle">Acumula Mes:</div>
                        </div>
                        <div class="p-col-fixed inside" style="width:33%">
                        <div class="tContent">{{liquidationData.accumulate_commissions.old}}</div>
                        <div class="tContent">{{liquidationData.accumulate_commissions.present}}</div>
                        <div class="tContent">{{liquidationData.accumulate_commissions.total}}</div>
                        </div>
                    </div>
                </div>

                <div class="p-col">
                    <div class="p-grid">
                        <div class="p-col inside">
                            <div class="tTitle">Reserva Acumulada:</div>
                            <div class="tTitle">Presente Semana:</div>
                            <div class="tTitle">Acumula. Mensual:</div>
                        </div>
                        <div class="p-col-fixed inside"  style="width:33%">
                            <div class="tContent">{{liquidationData.accumulate_reserve.old}}</div>
                            <div class="tContent">{{liquidationData.accumulate_reserve.present}}</div>
                            <div class="tContent">{{liquidationData.accumulate_reserve.total}}</div>
                        </div>
                    </div>
                </div>

                <div class="p-col">
                    <div class="p-grid">
                        <div class="p-col inside">
                            <div class="tTitle">Total:</div>
                            <div class="tTitle">Otros valores:</div>
                            <div class="tTitle">Devengado:</div>
                            <div class="tTitle">Mas otros Creditos:</div>
                            <div class="tTitle">Menos otros Debitos:</div>
                            <div class="tTitle">Neto a Pagar:</div>
                            <div class="tTitle">Saldo a pagar:</div>
                        </div>
                        <div class="p-col-fixed inside" style="width:33%">
                            <div class="tContent">{{liquidationData.totals_table.total}}</div>
                            <div class="tContent">{{liquidationData.totals_table.others_values}}</div>
                            <div class="tContent">{{liquidationData.totals_table.accrued}}</div>
                            <div class="tContent">{{liquidationData.totals_table.add_credits}}</div>
                            <div class="tContent">{{liquidationData.totals_table.sub_debits}}</div>
                            <div class="tContent">{{liquidationData.totals_table.net_pay}}</div>
                            <div class="tContent">{{liquidationData.totals_table.balance}}</div>
                        </div>
                    </div>
                </div>
            </div>
            </template>
        </div>
    </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>
  </div>
</template>
<script>
    import bouncer from "../../../../helpers/bouncer";
    import service from '../../service/liquidations.service'
    import moment from "moment"
    import commissionService from '../../service/processedEnrollments.service'
    import commissionsService from '../../service/commissions.service'
    import {mapGetters} from "vuex";
    export default {
      mixins:[bouncer],
        components: {

        },
        data() {
            return {
              observationRevert:null,
              itemDeleteOthersValues:0,
              itemDeleteLoans:0,
                loading: false,
                liquidationData: {},
                commissionData: {},
                itemsT:[],
                items: null,
                items2: null,
                items3: null,
                items4: null,
                items5: null,
                inputLiq: null,
                dateSel: null,
                inputLiqDesc: null,
                currentItem: null,
                // currentItem2: null,
                deleteItem: null,
                deleteDialog: false,
                contractDialog: false,
                commissionsDialog: false,
              deleteDialogOtherValues:false,
              deleteDialogLoans:false,
                pdf: false,
                valDialog: false,
                aprDialog: false,
                fTR: 1,
                listaSel: null,
                listaSelConcepto: null,
                listaSelOtro: null,
                lista: [],
                inputOthers: [],
                listaOtros : [],
                listaConceptos : [],
                info: null,
                generateDialog: false,
                disable: false,
            }
        },

        created(){
          if(this.canAccess('liquidaciones_read')){
            if (this.$route.params.id) {
              this.loading = true;
              service.get(this.$route.params.id).then(x => {
                this.currentItem = x.data.body[0]
                this.liquidationData = x.data;
                this.observationRevert=this.liquidationData.head?.observation_revert;
                this.breadcrumbCreate(x.data.head.id)
                this.lista = x.data.cb_concepts;
                this.listaOtros = x.data.cb_others
                this.listaConceptos = x.data.cb_loans
                this.items2 = x.data.body[0]?.childrens??[]
                x.data.head.status==='Validada' ? this.disable = true : this.disable = false
              }).finally(() =>  this.loading = false);

            }
            if (this.$route.params.id) {
              this.inputOthers[1] = this.inputOthers[2] = this.inputOthers[4] = this.inputOthers[5] = this.inputOthers[6] = 0
              this.getData()
            }
          }
        },
        computed: {
            ...mapGetters('bread', ['breadcrumb']),
        },
        methods: {
        getAbusoluteValue(value){
          return Math.abs(value);
        },
            breadcrumbCreate(liquidacionId){
                this.$store.dispatch('bread/setItems', [
                    {label: 'Registro', url:"javascript:void(0);"},
                    {label: 'Lista de Liquidaciones', url:"javascript:void(0);"},
                    {label: 'Detalle de Liquidación '+ liquidacionId , url:"javascript:void(0);"},
                ]);
            },
            getData(){
                this.loading = true;
                service.get(this.$route.params.id).then(x => {
                    this.items = x.data.body;
                    this.items3 = x.data.tb_liquidations_concepts
                    this.liquidationData = x.data;
                    this.items4 = x.data.tb_other_values
                    this.items5 = x.data.tb_loans
                }).finally(() =>  this.loading = false);
            },
            selectData() {
                this.fTR = 2
                this.loading = true
                this.items2 = this.currentItem.childrens
                this.loading = false
            },
            openContract(id){
                this.contractDialog = true
                commissionService.get(id).then(x => {
                    this.commissionData = x.data;
                }).finally(() =>  this.loading = false);
            },
            openCommissions(id){
                this.itemsT = []
                this.commissionsDialog = true
                commissionsService.get(id).then(x => {
                    this.itemsT = x.data?.commissions;
                }).catch((err) => {
                  const message = err.response.data;
                  let error = this.$utils.formatError(message);
                  this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});

                }).finally(() =>  this.loading = false);
            },
            addOther(){
                const data = {
                    'code' : this.listaSelOtro.id,
                    'enrollment' : this.inputOthers[0],
                    'debits' : this.inputOthers[1],
                    'credits' : this.inputOthers[2],
                    'description' : this.inputOthers[3]
                }
                if (this.inputOthers[1] === 0 && this.inputOthers[2] === 0){
                    this.$toast.add({severity: 'error', summary: 'Error', detail: 'Debito/Credito obligatiorio', life: this.$utils.toastLifeTime()});
                    return
                }
                service.addOthers(this.$route.params.id,data).then(() => {
                    this.inputOthers = []
                    this.liquidationData = {}
                    this.$toast.add({severity: 'success', summary: 'Éxito', detail: 'Otro valor agregado', life: this.$utils.toastLifeTime()});
                    this.$nextTick(() => {
                        this.$emit('closeSidebar', false)
                    })
                }).catch((err) => {
                    const message = err.response.data;
                    let error = this.$utils.formatError(message);
                    this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
                }).finally(() =>  {
                    this.getData();
                })
            },
          getPdf(){
                // this.pdf = true
                service.getPDF(this.$route.params.id).then(async (x) => {
                    this.info = x.data;
                    // this.generatePDF(x.data)
                    this.loading=true;
                    var byteCharacters = atob(x.data);
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);
                    var blob = new Blob([byteArray], { type: "application/pdf" });

                    await Object.assign(document.createElement('a'), {
                      target: '_blank',
                      href: URL.createObjectURL(blob),
                    }).click();
                    this.loading=false;
                })


            },
            addLoan(){
                const data = {
                    'code' : this.listaSelConcepto.id,
                    'value_loan' : this.inputOthers[4],
                    'date_at' : moment(this.dateSel).format("YYYY-MM-DD"),
                    'value_amortize' : this.inputOthers[5],
                    'balance' : this.inputOthers[6]
                }
                service.addLoans(this.$route.params.id,data).then(() => {
                    this.inputOthers = []
                    this.liquidationData = {}
                    this.$toast.add({severity: 'success', summary: 'Éxito', detail: 'Prestamo agregado', life: this.$utils.toastLifeTime()});
                    this.$nextTick(() => {
                        this.$emit('closeSidebar', false)
                    })
                }).catch((err) => {
                    const message = err.response.data;
                    let error = this.$utils.formatError(message);
                    this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
                }).finally(() =>  {
                    this.getData();
                })
            },
            newCode(id){
                service.updateRow(id,{"code":this.listaSel.id}).then(() => {
                    this.getData()
                    this.$toast.add({severity: 'success', summary: 'Éxito', detail: 'Liquidación cambiada', life: this.$utils.toastLifeTime()});
                    this.$nextTick(() => {
                        this.$emit('closeSidebar', false)
                    })
                }).catch((err) => {
                    const message = err.response.data;
                    let error = this.$utils.formatError(message);
                    this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
                });
            },
            confirmDelete(val) {
                this.deleteItem = val;
                this.deleteDialog = true;
            },
            updateLiq(rowId,key){
                var data
                if(key==='value'){

                    data = {'value' : document.getElementById('Input'+rowId).value}
                }
                else if(key==='description'){

                    data = {'description' : document.getElementById('Description'+rowId).value}
                }
                service.updateRow(rowId, data).then(()=>{
                this.getData()
                this.$toast.add({severity: 'success', summary: 'Éxito', detail: 'Valor actualizado', life: this.$utils.toastLifeTime()});
                this.$nextTick(() => {
                    this.$emit('closeSidebar', false)
                })
                }).catch((err) => {
                    const message = err.response.data;
                    let error = this.$utils.formatError(message);
                    this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
                })

            },
            generateLiq(){
                service.generateLiq(this.$route.params.id).then(()=>{
                    this.generateDialog = false
                    this.getData()
                    this.$toast.add({severity: 'success', summary: 'Éxito', detail: 'Liquidación generada', life: this.$utils.toastLifeTime()});
                    this.$nextTick(() => {
                        this.$emit('closeSidebar', false)
                    })
                }).catch((err) => {
                    const message = err.response.data;
                    let error = this.$utils.formatError(message);
                    this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
                })
            },
            deleteRow() {
                service.deleteRow(this.deleteItem.id).then(() => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Eliminado correctamente',
                        life: this.$utils.toastLifeTime()
                    });
                }).catch((err) => {
                    const message = err.response.data;
                    let error = this.$utils.formatError(message);
                    this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
                }).finally(() =>  {
                    this.deleteDialog = false;
                    this.getData();
                })
            },
            deleteRowOtherValues() {
                service.deleteOthers(this.itemDeleteOthersValues).then(() => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Eliminado correctamente',
                        life: this.$utils.toastLifeTime()
                    });
                }).catch((err) => {
                    const message = err.response.data;
                    let error = this.$utils.formatError(message);
                    this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
                }).finally(() =>  {
                    this.deleteDialogOtherValues = false;
                    this.getData();
                })
            },
            deleteRowLoans() {
                service.deleteLoans(this.itemDeleteLoans).then(() => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Eliminado correctamente',
                        life: this.$utils.toastLifeTime()
                    });
                }).catch((err) => {
                    const message = err.response.data;
                    let error = this.$utils.formatError(message);
                    this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
                }).finally(() =>  {
                    this.deleteDialogLoans = false;
                    this.getData();
                })
            },
            changeStatus(status){
                var data
                var msg
                this.valDialog = this.aprDialog = false
                if(status==='Aprobada'){

                    data = {'status' : 'Aprobada'}
                    msg = 'Liquidación Aprobada'
                    this.liquidationData.head.status = 'Aprobada'
                    this.disable = true
                }
                else if(status==='Validada'){

                    data = {'status' : 'Validada'}
                    msg = 'Liquidación Validada'
                    this.liquidationData.head.status = 'Validada'
                }
                service.changeStatus(this.$route.params.id,data).then(() => {
                    this.getData()
                    this.$toast.add({severity: 'success', summary: 'Éxito', detail: msg, life: this.$utils.toastLifeTime()});
                    this.$nextTick(() => {
                        this.$emit('closeSidebar', false)
                    })
                }).catch((err) => {
                    const message = err.response.data;
                    let error = this.$utils.formatError(message);
                    this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
                });
            }
        },
    }
</script>
<style lang="scss" scoped>
    .notification-revert{
      width: 100%;
      padding: 0.625rem;
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
    }

    .p-progressbar {
        height: 1px;
    }

    .hide{ display: none; }

    .p-field{
        display: inline-flex;
    }

    .p-unique{
        margin-top: 1rem;
    }

    .p-toolbar {
        margin-top: 10px;
        background-color: white;
    }

    .p-toolbar-group-right, .valButtons, .full{
        width: 100%;
    }

    .full{
        text-align: center;
        margin: 0 0.15rem;
        .p-col{
            margin: 1rem;
            .inside{
                padding: 0;
                margin: 2rem 0 0;
            }
        }
    }

    .p-float-label label{
        margin-left: .5rem;
    }

    .p-datatable{
        border-left: 1px solid #e9ecef;
        border-right: 1px solid #e9ecef;
        &-header{
            .p-toolbar{
                background: none;
                border: none;
            }
        }
    }

    .table-fix{
        .p-datatable-footer{
            padding: 0;
        }
        .p-inputnumber-input{
            width:100% !important;
        }
    }

    .ib{
        border: 1px solid #2196F3;;
    }

    .valButtons{
        margin: 2rem 0;
        .p-button{
            float: right;
        }
    }

    .df,.dff{
        height: 100%;
    }

    .p-dropdown .p-dropdown-label, .p-dropdown .p-dropdown-label.p-placeholder{
        padding: 0.5rem !important;
    }

    .tFooter{
        width: 100%;
        display: flex;
        td{
            width: 16.7%;
            padding: 1rem;
            div
            // , span, input
            {
                width: auto;
            }
            // &:last-child{
            //     width: 2.5%;
            //     // padding-left: 2.75rem;
            // }
        }
    }

    .fTR{
        background: #E3F2FD !important;
    }

    .tTitle, .tContent{
        border: 1px solid #e9ecef;
        padding: 1rem;
    }

    .tTitle{
        text-align: left;
        background-color: #f8f9fa;
    }

    .p-inputtext{
        width: 75%;
    }

    .p-datatable-emptymessage{
        display: none !important;
    }

    .fix{
        width: 1.75rem;
        height: 1.75rem !important;
        background: transparent;
        border: transparent;
        .pi::before {
            font-size: 1.15rem;
            color: #000 !important;
        }
    }

    .p-breadcrumb{
        margin: 1.6rem auto;
        padding: 2rem;
    }

    .p-checkbox{
        cursor: none;
    }
</style>
